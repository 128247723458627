<template>
  <div class="porderDetailContainer">
    <div v-for="(item,index) in $parent.porderDetailArr" :key="index" class="porderDetailItemContainer">
      <div class="porderDetailItemLeftContainer">
        <div class="porderNumber">
          <div>{{ $fanyi("配送单号") }}</div>
          <router-link :to="{ path: '/deliveryDetails', query: { pOrder_sn: item.porder_sn } }"
                       class="cursorPointer"
                       target="_blank">
            {{ item.porder_sn }}
          </router-link>
        </div>
        <div class="flex">
          <div>
            <div class="tableHeader">
              <div>{{ $fanyi("发送方式") }}</div>
              <div>{{ $fanyi("计费数据") }}</div>
              <div>{{ $fanyi("费用（元）") }}</div>
            </div>
            <div class="tableItemListContainer">
              <div v-for="(boxItem,boxIndex) in item.logistics_group" :key="boxIndex" class="tableItem">
                <div>{{ boxItem.logistics_name }}</div>
                <div>{{ boxItem.amount_data }}</div>
                <div>{{ boxItem.total_money_rmb }}</div>
              </div>
            </div>
          </div>
          <div class="pageJumpBtn" @click="porderPageJump(item)">{{ $fanyi("查看详细") }}</div>
        </div>
      </div>
      <div class="porderDetailItemRightContainer">
        <div class="title">{{ $fanyi("合计") }}</div>
        <div class="marginBottom20 flexAndCenter">
          <div class="fontSize14 color333 width195">{{ $fanyi("国际运费") }}（円）</div>
          <div>{{ item.amount.international_logistics_fee_jpy }}</div>
        </div>
        <div class="marginBottom20 flexAndCenter">
          <div class="flexAndCenter width195">
            <div class="fontSize14 color333">{{ $fanyi('其他费用') }}（円）</div>
            <el-popover placement="bottom" trigger="hover">
              <div class="otherAmountDetailContainer">
                <div class="otherPriceTitle">{{ $fanyi('其他费用明细') }}</div>
                <div class="otherExpensesHead">
                  <ul class="flexAndCenter color333 fontSize14">
                    <li class="optionSelect">{{ $fanyi('名义') }}</li>
                    <li class="num">{{ $fanyi('费用（元）') }}</li>
                  </ul>
                </div>
                <!-- 单个商品 -->
                <div class="otherExpensesFooter">
                  <ul class="flexAndCenter color333"
                      style="background: transparent">
                    <li class="optionSelect" style="line-height: normal">
                      {{ item.amount.other_amount_detail.length > 0 ? item.amount.other_amount_detail[0].desc : '' }}
                    </li>
                    <li class="num">
                      {{ item.amount.other_fee }}
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <div>合計</div>
                  <div>{{ item.amount.other_fee }}</div>
                </div>
              </div>
              <div slot="reference" class="fontSize14 colorB4272B cursorPointer"
                   style="text-decoration-line: underline;">
                {{ $fanyi('详细') }}
              </div>
            </el-popover>
          </div>
          <div>{{
              item.amount.other_fee_jpy
            }}
          </div>
        </div>
        <div class="flexAndCenter">
          <div class="fontSize14 color333 width195">{{ $fanyi('合计金额') }}（円）</div>
          <div>{{ item.amount.sum_amount_jpy }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    porderPageJump(item) {
      window.open(`/deliveryDetails?pOrder_sn=${item.porder_sn}`)
    }
  }
}

</script>

<style lang="scss" scoped>
.porderDetailContainer {
  margin: 0 auto;

  .porderDetailItemContainer {
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 820px;
    margin-bottom: 15px;

    .porderDetailItemLeftContainer {
      width: 480px;
      padding: 24px 18px 12px 24px;
      background: #F6F6F6;

      .porderNumber {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        div {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }

        a {
          font-size: 18px;
          color: #2742B4;
          margin-left: 14px;
        }
      }

      .pageJumpBtn {
        width: 88px;
        height: 34px;
        background: #B4272B;
        border-radius: 4px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
        margin-top: 67px;
      }

      .tableHeader {
        width: 330px;
        height: 32px;
        background: #EEEEEE;
        border-radius: 4px;
        padding: 0 15px;
        display: flex;

        div {
          font-size: 14px;
          color: #333333;
          line-height: 32px;
          flex: 1;
        }
      }

      .tableItemListContainer {
        max-height: 72px;
        overflow: hidden;

        .tableItem {
          padding: 17px 15px 0;
          display: flex;

          div {
            font-size: 14px;
            color: #333333;
            flex: 1;
          }
        }
      }
    }

    .porderDetailItemRightContainer {
      width: 340px;
      background: #EEEEEE;
      padding: 13px 0 24px 24px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-bottom: 15px;
      }
    }
  }

  .marginBottom20 {
    margin-bottom: 20px;
  }

  .width195 {
    width: 195px;
  }

  .porderDetailItemContainer:last-child {
    margin-bottom: 0;
  }
}
</style>