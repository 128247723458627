<template>
  <div class="orderDetailContainer">
    <div v-for="(item,index) in $parent.orderDetailArr" :key="index" class="orderDetailItemContainer">
      <div class="orderDetailItemLeftContainer">
        <div class="porderNumber">
          <div>{{ $fanyi("注文番号") }}</div>
          <router-link :to="{ path: '/OrderDetails', query: { order_sn: item.order_sn } }"
                       class="cursorPointer"
                       target="_blank">
            {{ item.order_sn }}
          </router-link>
        </div>
        <div class="orderDetailListContainer">
          <div v-for="(orderItem,orderIndex) in item.order_detail" :key="orderIndex"
               class="newOrderDetailItemContainer">
            <div class="No">No.{{ orderIndex + 1 }}</div>
            <el-popover placement="right" trigger="hover">
              <img :src="orderItem.pic" alt="" style="width: 300px;height: 300px;">
              <img slot="reference" :src="orderItem.pic" alt="" style="width: 50px;height: 50px;">
            </el-popover>
            <div class="goodsAttribute">
              <div>{{ $fanyi("商品在库无，付款后自动取消") }}</div>
              <div class="u-line" style="width: 300px">{{ orderItem.detail_str }}</div>
            </div>
          </div>
        </div>
        <div class="pageJumpBtn" @click="orderPageJump(item)">{{ $fanyi("查看详细") }}</div>
      </div>
      <div class="porderDetailItemRightContainer">
        <div class="title">{{ $fanyi("合计") }}</div>
        <div class="marginBottom20 flexAndCenter">
          <div class="fontSize14 color333 width195">{{ $fanyi("商品金额") }}（元）</div>
          <div>{{ item.order_amount.goods_amount_rmb }}</div>
        </div>
        <div class="marginBottom20 flexAndCenter">
          <div class="flexAndCenter width195">
            <div class="fontSize14 color333">{{ $fanyi('手数料') }}（元）</div>
            <div class="fontSize14 colorB4272B cursorPointer"
                 @click="getOrderServiceDetail(item)"
                 style="text-decoration-line: underline;">
              {{ $fanyi('详细') }}
            </div>
          </div>
          <div>{{ item.order_amount.service_amount_rmb }}</div>
        </div>
        <div class="marginBottom20 flexAndCenter">
          <div class="flexAndCenter width195">
            <div class="fontSize14 color333">{{ $fanyi('オプション手数料') }}（元）</div>
            <div class="fontSize14 colorB4272B cursorPointer"
                 @click="getOrderOptionDetail(item)"
                 style="text-decoration-line: underline;">
              {{ $fanyi('详细') }}
            </div>
          </div>
          <div>{{ item.order_amount.option_amount_rmb }}</div>
        </div>
        <div class="marginBottom20 flexAndCenter">
          <div class="fontSize14 color333 width195">{{ $fanyi("国内送料") }}（元）</div>
          <div>{{ item.order_amount.predict_logistics_price_rmb }}</div>
        </div>
        <div class="marginBottom20 flexAndCenter">
          <div class="flexAndCenter width195">
            <div class="fontSize14 color333">{{ $fanyi('其他费用') }}（元）</div>
            <div slot="reference" class="fontSize14 colorB4272B cursorPointer"
                 style="text-decoration-line: underline;" @click="getOrderOtherFeeDetail(item)">
              {{ $fanyi('详细') }}
            </div>
          </div>
          <div>{{ item.order_amount.other_fee_rmb }}</div>
        </div>
        <div class="flexAndCenter marginBottom20">
          <div class="fontSize14 color333 width195">{{ $fanyi('合计金额') }}（元）</div>
          <div>{{ item.order_amount.sum_amount_rmb }}</div>
        </div>
        <div class="flexAndCenter">
          <div class="fontSize14 color333 width195">{{ $fanyi('合计金额') }}（円）</div>
          <div>{{ item.order_amount.sum_amount_jpy }}</div>
        </div>
      </div>
    </div>
    <div class="dialogContainer">
      <!--    option费用明细-->
      <el-dialog
          :title="$fanyi('オプション詳細')"
          :visible.sync="showGoodsOptionDialogVisible"
          center
          width="440px">
        <div class="optionHead" style="margin-top: 30px">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect" style="min-width: 200px;text-align: left">{{ $fanyi("内容") }}</li>
            <li class="num">{{ $fanyi("数量") }}</li>
            <li class="estimatedCost">{{ $fanyi("小计（元）") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="optionFooter">
          <ul v-for="(listItem, listIndex) in selectOptionList" :key="listIndex" class="flexAndCenter color333"
              style="background: transparent">
            <li class="optionSelect" style="text-align: left;min-width: 200px;line-height: normal!important;">{{
                listItem.option_name
              }}
            </li>
            <li class="num">{{ listItem.num }}</li>
            <li class="estimatedCost">{{ listItem.subtotal }}</li>
          </ul>
        </div>
      </el-dialog>
      <!--    单番手续费明细-->
      <el-dialog
          :title="$fanyi('手续费')"
          :visible.sync="showGoodsServiceChargeDialogVisible"
          center
          width="440px">
        <div class="optionHead" style="margin-top: 30px">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect newOptionSelect">
              {{ $fanyi("番号") }}
            </li>
            <li class="num" style="max-width:150px;width:150px">手数料</li>
            <li class="estimatedCost" style="width:150px">{{ $fanyi("手续费(元)") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="optionFooter goodsServiceContainer">
          <ul v-for="(listItem, listIndex) in goodsTotalServiceChargeDetailsList" :key="listIndex"
              class="flexAndCenter color333"
              style="background: transparent">
            <li class="optionSelect newOptionSelect">{{ listItem.sorting }}</li>
            <li class="num" style="max-width:150px;width:150px">{{
                $fun.returnServiceRate(listItem.service_rate)
              }}%
            </li>
            <li class="estimatedCost" style="min-width:150px">{{ listItem.service_price.toFixed(2) }}</li>
          </ul>
        </div>
      </el-dialog>
      <!--      其他费用明细展示-->
      <el-dialog
          :title="$fanyi('其他费用明细')"
          :visible.sync="otherExpensesDialogVisible"
          center
          width="380px">
        <div class="otherExpensesHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect">{{ $fanyi("名义") }}</li>
            <li class="num">{{ $fanyi("费用（元）") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="otherExpensesFooter">
          <ul class="flexAndCenter color333" style="background: transparent">
            <li class="optionSelect">
              {{ otherPriceRemark }}
            </li>
            <li class="num">
              {{ otherPrice }}
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsTotalServiceChargeDetailsList: [],
      selectOptionList: [],
      otherPriceRemark: '',
      otherPrice: 0,
      showGoodsOptionDialogVisible: false,
      showGoodsServiceChargeDialogVisible: false,
      otherExpensesDialogVisible: false,
    }
  },
  methods: {
    orderPageJump(item) {
      window.open(`/OrderDetails?order_sn=${item.order_sn}`)
    },
    //展示手续费明细
    getOrderServiceDetail(item) {
      this.goodsTotalServiceChargeDetailsList = item.service_detail;
      this.showGoodsServiceChargeDialogVisible = true
    },
    //展示其他费用明细
    getOrderOtherFeeDetail(item) {
      this.otherPriceRemark = item.other_detail.detail[0].other_name;
      this.otherPrice = item.other_detail.detail[0].other_price;
      this.otherExpensesDialogVisible = true
    },
    //展示option费用明细
    getOrderOptionDetail(item) {
      this.selectOptionList = item.option_detail;
      this.showGoodsOptionDialogVisible = true
    },
  }
}

</script>

<style lang="scss" scoped>
.orderDetailContainer {
  margin: 0 auto;

  .orderDetailItemContainer {
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 820px;
    margin-bottom: 15px;

    .orderDetailItemLeftContainer {
      width: 480px;
      height: 340px;
      padding: 24px 18px 12px 24px;
      background: #F6F6F6;

      .porderNumber {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        div {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }

        a {
          font-size: 18px;
          color: #2742B4;
          margin-left: 14px;
        }
      }

      .pageJumpBtn {
        width: 88px;
        height: 34px;
        background: #B4272B;
        border-radius: 4px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
        margin-top: 16px;
      }

      .orderDetailListContainer {
        height: 174px;
        overflow-y: scroll;

        .newOrderDetailItemContainer {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .No {
            width: 42px;
            height: 24px;
            background: #9B9B9B;
            border-radius: 2px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            color: #FEFEFE;
            margin-right: 18px;
          }

          .goodsAttribute {
            margin-left: 12px;

            div:first-child {
              color: #333333;
              margin-bottom: 10px;
            }

            div:last-child {
              font-size: 14px;
              color: #999999;
            }
          }
        }

        .newOrderDetailItemContainer:last-child {
          margin-bottom: 0;
        }
      }
    }

    .porderDetailItemRightContainer {
      width: 340px;
      background: #EEEEEE;
      padding: 13px 0 24px 24px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-bottom: 15px;
      }
    }
  }

  .marginBottom20 {
    margin-bottom: 20px;
  }

  .width195 {
    width: 195px;
  }

  .porderDetailItemContainer:last-child {
    margin-bottom: 0;
  }

  .optionHead {
    ul {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #F0F0F0;
      height: 46px;

      li {
        text-align: center;
        line-height: 46px;
        font-size: 14px;
        color: #333;
      }

      .optionSelect {
        width: 200px;
        min-width: 200px;
        padding-left: 26px;
        box-sizing: border-box;
      }

      .newOptionSelect {
        min-width: 80px !important;
        width: 80px !important;
        max-width: 80px !important;
        padding-left: 0 !important
      }

      .num {
        width: 80px;
        max-width: 80px;
      }

      .estimatedCost {
        width: 100px;
        min-width: 100px;
      }
    }
  }

  .goodsServiceContainer {
    height: 600px;
    overflow: scroll;
  }

  .goodsServiceContainer::-webkit-scrollbar {
    display: none !important;
  }

  .optionFooter {
    max-height: 500px;
    overflow-y: scroll;

    ul {
      height: 50px;
      border-bottom: 1px solid #DFDFDF;

      li {
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #333;
      }

      .optionSelect {
        width: 200px;
        padding-left: 26px;
        box-sizing: border-box;
        min-width: 200px;
      }

      .newOptionSelect {
        min-width: 80px !important;
        width: 80px !important;
        max-width: 80px !important;
        padding-left: 0 !important;
        text-align: center !important;
      }

      .num {
        width: 80px;
        max-width: 80px;
        color: #B4272B;
        text-decoration: underline;
      }

      .estimatedCost {
        width: 100px;
        min-width: 100px;
      }
    }
  }

  .optionFooter::-webkit-scrollbar {
    width: 0;
  }

  .otherExpensesHead {
    ul {
      height: 46px;
      background: #F0F0F0;
      margin-top: 40px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      li {
        text-align: center;
        line-height: 46px;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
      }

      li:last-child {
        width: 120px;
      }
    }
  }

  .otherExpensesFooter {
    ul {
      height: 50px;

      li {
        text-align: center;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
        min-width: 200px;
      }

      li:last-child {
        width: 120px;
        min-width: 120px;
      }
    }
  }

  .otherTableTitle {
    .newLeft, .newCenter {
      width: 150px;
      text-align: center;
    }
  }

  .dialogContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          padding-bottom: 7px;
          border-bottom: 4px solid #B4272B;
        }

        .el-dialog__headerbtn {
          top: 30px;
          font-size: 19px;

          .el-dialog__close {
            color: #B4272b;
          }
        }
      }

      .el-dialog__body {
        padding: 0;
      }

      .el-dialog__footer {
        padding: 0;

        button {
          border-radius: 8px;
          height: 34px;
          width: 120px;
          line-height: 34px;
          padding: 0;
          font-size: 14px;
        }

        button:first-child {
          color: #B4272B;
          border: 1px solid #B4272B;
          margin-right: 26px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }

    .goodsAttributeEditTypeContainer {
      margin-top: 36px;

      .el-button + .el-button {
        margin-left: 26px;
      }
    }

    .goodsDetailContainer {
      .keyContainer {
        margin-top: 20px;
        min-width: 80px;
      }

      .valueAttributeListContainer {
        display: flex;
        flex-wrap: wrap;

        div {
          padding: 8px 16px;
          border-radius: 3px;
          box-sizing: border-box;
          border: 1px solid #DFDFDF;
          margin: 20px 0 0 20px;
          cursor: pointer;
        }

        .active {
          border: 1px solid #B4272B;
          color: #B4272B;
        }
      }
    }

    .artificialAttributeTitle {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background: #F0F0F0;
      margin-top: 20px;
      margin-right: 26px;

      div {
        width: 215px;
        height: 30px;
        padding-left: 8px;
        line-height: 30px;
      }
    }

    .artificialAttributeEditContainer {
      border-bottom: 1px solid #DFDFDF;

      .artificialAttributeEditLeftContainer {
        padding: 10px 8px;
      }
    }

    .addAttributeText {
      margin-top: 12px;
      text-decoration: underline;
      color: #50C136;
      cursor: pointer;
    }
  }
}
</style>